<template>
  <div class="recite">
    <div class="header">
      <div class="title">
        {{ title }}
      </div>
      <div class="sub-title">
        {{ subTitle }}
      </div>
    </div>
    <div class="main">
      <div class="item" v-for="(item, idx) in teach.content" :key="idx">
        <div class="sys" v-if="item.unitType == 2">
          <div class="text" v-html="item.content"></div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="left">
        <button class="recite-mode btn" :class="{ active: reciteMode }" @click="onReciteMode">
          背诵模式
        </button>
        <button class="text-mode btn" :class="{ active: !reciteMode }" @click="onReadMode">
          速记模式
        </button>
      </div>
      <div class="right">
        <button class="download btn active" @click="showCsWidget = true">下载完整版</button>
      </div>
      <wechatId v-if="showCsWidget" :title="'加老师微信领资料'" :vId="vId" :vUrl="vUrl"></wechatId>
    </div>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import wechatId from "@/components/wechatId.vue";
import { flexQrcodeApi } from "@/api/flexQrcodeApi.js";

export default {
  components: {
    wechatId,
  },
  data() {
    return {
      id: 0,
      clickCount: 0,
      isPop: true,
      showCsWidget: false,
      reciteMode: true,
      paragraphs: [],
      article: {
        paragraphs: [],
      },
      showGoodReputation: true,
      teach: {},
      title: "",
      subTitle: "",
      vId: "", // 后端给的微信id
      vUrl: "", //后端给的微信url
    };
  },
  methods: {
    onReciteMode() {
      this.reciteMode = true;
      this.clickCount++;
      const textList = document.querySelectorAll(".redtext");
      console.log("...", textList);
      for (const tmp of textList) {
        tmp.classList.remove("redtext");
        tmp.classList.add("hidetext");
      }
    },
    onReadMode() {
      this.reciteMode = false;
      this.clickCount++;
      const textList = document.querySelectorAll(".hidetext");
      console.log("...", textList);
      for (const tmp of textList) {
        tmp.classList.remove("hidetext");
        tmp.classList.add("redtext");
      }
    },
    getRecite(id) {
      if (id <= 0) {
        return;
      }
      flexQrcodeApi.recite(id).then((ret) => {
        if (ret.code == 0 && ret.data) {
          console.log(ret);
          let item = ret.data;
          this.teach = item.teach;
          this.title = item.title;
          document.title = this.title;
          this.subTitle = item.subTitle;
          this.vId = item.qrCode.weChat.wechatId;
          this.vUrl = item.qrCode.weChat.url;
          this.$nextTick(() => {
            this.listenOnHideTextClick();
          });
        }
      });
    },
    listenOnHideTextClick() {
      const textList = document.querySelectorAll(".hidetext");
      console.log("...", textList);
      for (const tmp of textList) {
        tmp.addEventListener("click", this.hideTextClick);
      }
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
    //切换文字的模式
    hideTextClick(e) {
      const elem = e.srcElement;
      // console.log('..........', elem, elem.classList);
      if (elem.classList.contains("hidetext")) {
        // 隐藏 => 展示
        elem.classList.remove("hidetext");
        elem.classList.add("redtext");
      } else {
        // 展示 => 隐藏
        elem.classList.remove("redtext");
        elem.classList.add("hidetext");
      }
      this.clickCount++;
    },
  },
  mounted() {
    this.listenOnCloseCsWidget();
    if (this.$route.query && this.$route.query.hideGood && this.$route.query.hideGood == 1) {
      this.showGoodReputation = false;
    }
    if (this.$route.query && this.$route.query.hidePop && this.$route.query.hidePop == 1) {
      this.isPop = false;
    }

    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getRecite(this.id);
    }
  },
  watch: {
    clickCount: function(val) {
      if (val % 4 == 0 && this.isPop == true) {
        this.showCsWidget = true;
      }
    },
  },
  beforeCreate() {
    document.title = "（必背）2020教资高频考点梳理";
  },
};
</script>

<style lang="less" scoped>
.recite {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  //align-items: center;
  .header {
    display: flex;
    flex-direction: column;
    height: 238px;
    background: linear-gradient(180deg, rgb(143, 112, 255) 0%, rgb(62, 67, 202) 100%);
    flex: 0 0 auto;
    .title {
      margin: 20px 0 8px 0;
      font-size: 1.6rem;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
    }
    .sub-title {
      font-size: 1rem;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    // align-items: center;
    margin: -140px 20px 20px 20px;
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0 0.1rem 0.2rem #eee;
    border-radius: 0.5rem;
    font-size: 1.14rem;
    font-weight: 500;
    color: #323233;
    letter-spacing: 0.4px;
  }
  .footer {
    margin: 0 20px 8px 20px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn {
      height: 2.7rem;
      font-size: 1rem;
      border: 1px solid #4d52d1;
      padding: 0 10px;
      border-radius: 0.2rem;
      background: #fff;
      color: #323233;
    }
    .active {
      background: #4d52d1;
      border: none;
      font-weight: 500;
      color: #ffffff;
    }
    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .right {
      .download {
        width: 142px;
      }
    }
  }
}
</style>

<style lang="less">
img {
  width: 100%;
  height: auto;
}
.md {
  font-size: 1.14rem;
  font-weight: 400;
  color: #323233;
  letter-spacing: 0.4px;
  .md-pre {
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-radius: 4px;
    background-color: #dddddd;
    padding: 5px;
    margin: 5px 0px;
    box-shadow: 2px 2px 4px #aaaaaa;
  }
  .md-p {
    margin: 0rem 0rem 1.2rem 0rem;
    &:last-child {
      margin: 0rem;
    }
  }
  .md-ul {
    display: block;
    margin: 0 0 15px 0;
    .md-li {
      margin-left: 15px;
      display: block;
      &::before {
        content: "◦";
        margin: 0 10px 0 0px;
        font-size: 1.5rem;
      }
      .md-ul {
        .md-li {
          margin-left: 15px;
          display: block;
          &::before {
            content: "-";
            margin: 0 10px 0 0px;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .hidetext {
    background: #fe611c;
    color: #fe611c;
  }
  .redtext {
    color: red;
  }
}
</style>
